import React from 'react'

import logo from "../assets/images/logo.png"
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <section class="footer " id="footer">
    <div class="container-fluid">
        <div class="media-container row align-center mbr-white">
            <div className="col-md-4 col-lg-4 col-sm-12 logo-block">
            <Link to="/" className="nav-logo">
					<img src={logo} alt="Logo"/>
                    {/* <h2 className='logo-text-name'>Animal Conservation <br/>Ecology Lab</h2> */}
				</Link>
            </div>
                  <div className="col-md-8 col-lg-8 col-sm-12 address-area">
                      <ul>
                          <li>CSIR-Centre for Cellular and Molecular Biology</li>
                          <li>Laboratory for Conservation of Endangered Species (LaCONES)</li>
                          <li>Attapur, Hyderguda, Hyderabad 500 048. India</li>
                      </ul>
                      <ul>
                        <li>Tel: +91-40-24006443</li>
                        <li>Fax: +91-40-24006441</li>
                        <li>Email: karthik@ccmb.res.in</li>
                      </ul>
                  </div>
            <div class="col-12 col-lg-12 col-sm-12 copy-right">
                <p class="mbr-text mb-0 mbr-fonts-style display-7">
                    © Copyright 2024 ACE Labs - All Rights Reserved
                </p>
            </div>
        </div>
    </div>
</section>
  )
}

export default Footer