// LoadingOverlay.js
import React from 'react';
import "./LoaderStyle.css"
const LoadingOverLay = () => (
<div class="spinner-box">
  <div class="three-quarter-spinner"></div>
</div>
);

export default LoadingOverLay;
