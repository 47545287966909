import React,{useEffect,useState} from 'react'
import TeamCard from '../components/TeamCard';
import {db} from '../firebase';
import { collection, getDocs } from "firebase/firestore";
const Team = () => {

  const [memberData, setMemberData] = useState([]);
  const [loading , setLoading] = useState(false)

  const initialDataFetch = async() => {
    await getDocs(collection(db, "members"))
        .then((querySnapshot)=>{               
            const newData = querySnapshot.docs
                .map((doc) => ({...doc.data(), id:doc.id }));
                const filterData = [...newData].filter(item => item.type === 0).sort((a, b) => a.designationId - b.designationId);
    
                setMemberData(filterData);  
                setLoading(false)
        })
};
useEffect(() => {
  setLoading(true)
    initialDataFetch();


}, []);



  return (
<section id="team" class="team content-section">

      <div class="container justify-content-center text-align-center">

      {memberData.length >0 ? (
        <>
        <div class="row justify-content-center">


      {memberData.filter(data => data.designationId === 0 || data.designationId === 1).map((data, index) => (
        <TeamCard
          name={data.name}
          position={data.designationName}
          img={data.imgURL}
          about={data.about}
          email={data.email}
          index={index}
        />
      ))}

        </div>

<div class="row justify-content-center">


{memberData.filter(data => data.designationId !== 0 && data.designationId !== 1)
  .sort((a, b) => a.name.localeCompare(b.name)) // Sort by name in ascending order
  .map((data, index) => (
  <TeamCard
    name={data.name}
    position={data.designationName}
    img={data.imgURL}
    about={data.about}
    email={data.email}
    index={index}
  />
))}


  </div>
  </>



                  ) : (
                    
                      <p style={{ textAlign: "center" }}>No Data Available</p>
                    
                  )}
      </div>


</section>
  )
}

export default Team;






      {/* <div class="container">
        <div class="row justify-content-center">
            <div className='col-md-12,col-sm-12 team-heading-container'>
                <h3 className='team-heading'>Staff</h3>
            </div>
          {team.map((data, index) => {
              if (data.type === 2) {
                return (
                  <TeamCard  name={data.name} position={data.position} img={data.img} about={data.about} email={data.email} year={data.year}/>
                );
              }
          })}
        </div>
      </div>
      <div class="container">
        <div class="row justify-content-center">
            <div className='col-md-12,col-sm-12 team-heading-container'>
                <h3 className='team-heading'>Post-doc</h3>
            </div>
          {team.map((data, index) => {
              if (data.type === 3) {
                return (
                  <TeamCard  name={data.name} position={data.position} img={data.img} about={data.about} email={data.email} year={data.year}/>
                );
              }
          })}
        </div>
      </div>
      <div class="container">
        <div class="row justify-content-center">
            <div className='col-md-12,col-sm-12 team-heading-container'>
                <h3 className='team-heading'>Graduate Students</h3>
            </div>
          {team.map((data, index) => {
              if (data.type === 4) {
                return (
                  <TeamCard  name={data.name} position={data.position} img={data.img} about={data.about} email={data.email} year={data.year}/>
                );
              }
          })}
        </div>
      </div>
      <div class="container">
        <div class="row justify-content-center">
            <div className='col-md-12,col-sm-12 team-heading-container'>
                <h3 className='team-heading'>Research Assistants</h3>
            </div>
          {team.map((data, index) => {
              if (data.type === 5) {
                return (
                  <TeamCard  name={data.name} position={data.position} img={data.img} about={data.about} email={data.email} year={data.year}/>
                );
              }
          })}
        </div>
      </div> */}