import {BrowserRouter,Routes,Route} from "react-router-dom";
import Home from "./pages/Home"
import People from "./pages/People"
import ContactUs from "./pages/ContactUs";
import GalleryPage from "./pages/GalleryPage";
import ResearchPage from "./pages/ResearchPage";
import PublicationPage from "./pages/PublicationPage";
import Collaborators from "./pages/Collaborators";
import Alumini from "./pages/Alumini"
import './App.css';
import { Opportunities } from "./pages/Opportunities";

function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/people" element={<People />} />
      <Route path="/contact" element={<ContactUs />} />
      <Route path="/gallery" element={<GalleryPage />} />
      <Route path="/research" element={<ResearchPage />} />
      <Route path="/publications" element={<PublicationPage />} />      
      <Route path="/collaborators" element={<Collaborators />} />
      <Route path="/opportunities" element={<Opportunities />} />
      <Route path="/Alumini" element={<Alumini />} />

    </Routes>
    </BrowserRouter>
  );
}

export default App;
