import NavBar from "../components/NavBar";
import TitleCard from "../components/TitleCard";
import aceLogo from "../assets/images/ace_logo.png"

import Footer from "../components/Footer";


export const Opportunities = () => {
    return (
        <>
            <NavBar />
            <TitleCard heading="Opportunities" subHeading="Explore Current Openings, Internships, and Research Positions"/>
            <div className="opportunities">

                <div class="container photo-with-description">
                    <div class="col-md-6 col-sm-12 photo-section">
                        <img src={aceLogo} alt="Photo" />
                        <div class="overlay"></div>
                    </div>
                    <div class="col-md-6 col-sm-12 description">

                        <p>We are always looking to grow our team! Anyone interested in joining the Animal Conservation Ecology Lab can contact
                            Dr. Karthikeyan Vasudevan via email. I'm always pleased to talk about mutual research interests.
                            The applicants must eventually provide a current CV and a statement of their research interests.
                            We are dedicated to creating a welcoming and safe environment for everybody.</p>
                        <div className="details">
                            <h5>Dr.Karthikeyan Vasudevan</h5>
                            <h5>Chief Scientist</h5>
                            <h5 className="email">karthik@ccmb.res.in</h5>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="container  opportunities">
                <div className="heading-section">
                    <h1>Opportunities</h1>
                </div>
                <div className="row">
                    <div className="col-md-5">
                        <div class="photo-section">
                            <img src={profile} alt="Photo" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div class="description">
                            <p>
                                We are always looking to grow our team! Anyone interested in joining the Animal Conservation Ecology Lab can contact 
                                Dr. Karthikeyan Vasudevan via email. I'm always pleased to talk about mutual research interests. 
                                The applicants must eventually provide a current CV and a statement of their research interests.
                                We are dedicated to creating a welcoming and safe environment for everybody.</p>
                            <div className="details">
                                <h5>Dr.Karthikeyan Vasudevan</h5>
                                <h5>Chief Scientist</h5>
                                <h5 className="email">karthik@ccmb.res.in</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <Footer />
        </>
    );

}