import React ,{useState,useEffect} from 'react'
import { AiOutlineLeft, AiOutlineRight ,AiOutlineClose} from "react-icons/ai"
import "./gallery.css"
import {db} from '../firebase';
import { collection, getDocs } from "firebase/firestore";
const GridGallery = () => {

    const [showViewer, setShowViewer] = useState(false);
    const [currentImage, setCurrentImage] = useState(0);
    const [images, setImages] = useState([]);
    const [groupedArray, setGroupedArray] = useState([]);


  
    const openViewer = (outerIndex,innerIndex) => {
      const index = ((outerIndex)*6)+innerIndex;
      setCurrentImage(index);
      setShowViewer(true);
    };
  
    const closeViewer = () => {
      setShowViewer(false);
    };
  
    const nextImage = () => {
      setCurrentImage((currentImage + 1));
      if(images.length-2 < currentImage){
        setCurrentImage(0);
      }
    };
  
    const prevImage = () => {
      setCurrentImage((currentImage -1) );
      if(currentImage <= 0 ){
        setCurrentImage(images.length-1);
      }

    };
  
  // kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk
    // var groupedArray = [];
    // for (var i = 0; i < gallery.length; i += 6) {
    //   groupedArray.push(gallery.slice(i, i + 6));
    // }
    // console.log(groupedArray)
// kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk

const initialDataFetch = async() => {
  await getDocs(collection(db, "gallery"))
      .then((querySnapshot)=>{               
          const newData = querySnapshot.docs
              .map((doc) => ({...doc.data(), id:doc.id }));
              setImages(newData);
              var tempGroupedArray = [];
              for (var i = 0; i < newData.length; i += 6) {
                tempGroupedArray.push(newData.slice(i, i + 6));
              }
            
              setGroupedArray(tempGroupedArray);
                            
  })
};



useEffect(() => {
  // const importAll = (r) => {
  //   return r.keys().map(r);
  // };

  // const galleryImages = importAll(require.context('../assets/images/gallery', false, /\.(JPG|png|jpe?g|svg)$/));
  // setImages(galleryImages)
  // var tempGroupedArray = [];
  // for (var i = 0; i < galleryImages.length; i += 6) {
  //   tempGroupedArray.push(galleryImages.slice(i, i + 6));
  // }

  // setGroupedArray(tempGroupedArray);
  initialDataFetch()
}, []);

  return (
    <>
    <div className="gallery-container container d-flex justify-content-center">
    {images.length>0 && 

    <div className="image-grid-gallery">
    {groupedArray.map((innerArray, outerIndex) => (
    <div className="angry-grid" key={outerIndex} >


    {innerArray.map((data, innerIndex) => {
          if(innerIndex<6){
            const dynamicClassName = `item-${innerIndex}`;
            return (
          <div id={dynamicClassName} onClick={() => openViewer(outerIndex,innerIndex)}>
              <img src={data.imgURL} alt="photo" />
              {/* <div className="overlay"><span>{data.title}</span></div> */}
          </div>
              );
            }
          })}
    
  </div>
))}

</div>


        }

  </div>

    {showViewer && (
        <div className="image-viewer">
          <span className="close-button" onClick={closeViewer}>
            <AiOutlineClose />
          </span>
          <img src={images[currentImage].imgURL} alt={`Image ${currentImage + 1}`} />
          <button onClick={prevImage} className="prev-button">
            <AiOutlineLeft />
          </button>
          <button onClick={nextImage} className="next-button">
            <AiOutlineRight />
          </button>
        </div>
      )}

</>
  )
}

export default GridGallery;
