import React, { useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import { NavLink, useLocation } from 'react-router-dom';

import { FiMenu, FiX } from 'react-icons/fi';
// import Logo from '../images/logoTD.png';
// import twitter from '../icons/twitter.png';
// import facebook from '../icons/facebook.png';
// import instagram from '../icons/instagram.png';
// import linkedin from '../icons/linkedin.png';
import logo from "../assets/images/logo.png"
import aceLogo from "../assets/images/ace_logo.png"

const NavBar = () =>  {
 
	let location = useLocation(); 

	const [open, setOpen] = useState(false);
  //navbar scroll when active state
  const [navbar, setNavbar] = useState(false);




  //navbar scroll changeBackground function
  const changeBackground = () => {
    if (window.scrollY >= 66) {
      setNavbar(true)
    } else {
      setNavbar(false)
    }
  }

  useEffect(() => {
    changeBackground()
    // adding the event when scroll change background
    window.addEventListener("scroll", changeBackground)
  })

	const handleClick = () => {
		setOpen(!open);
	};

	const closeMenu = () => {
		setOpen(false);
	};

	return (
		<div className="navarea">
			<nav className={navbar ? "navbar-active" : "navbar"}>
			{/* <div className='hide'></div> */}

				<Link to="/" className="nav-logo">
					<img src={aceLogo} alt="Logo"/>
                    {/* <h2 className='logo-text-name'>Animal Conservation <br/>Ecology Lab</h2> */}
				</Link>
				<div onClick={handleClick} className="nav-icon">
					{open ? <FiX /> : <FiMenu />}
				</div>
				<ul className={open ? 'nav-links active' : 'nav-links'}>
					<li className="nav-item">
						<NavLink to="/" className={`${location.pathname === "/" ? "nav-link-tab-active" : "nav-link"}`} onClick={() => { closeMenu()}}>
							Home
						</NavLink>
					</li>
					<li className="nav-item">
						<NavLink to="/research" className={`${location.pathname === "/research" ? "nav-link-tab-active" : "nav-link"}`} onClick={() => { closeMenu()}}>
							Research
						</NavLink>
					</li>
					<li className="nav-item">
						<Link to="/publications" className={`${location.pathname === "/publications" ? "nav-link-tab-active" : "nav-link"}`} onClick={() => { closeMenu()}}>
							Publications
						</Link>
					</li>

					<li className="nav-item">
						<Link to="/gallery" className={`${location.pathname === "/gallery" ? "nav-link-tab-active" : "nav-link"}`} onClick={() => { closeMenu()}}>
							Gallery
						</Link>
					</li>
					<li className="nav-item">
						<Link to="/people" className={`${location.pathname === "/people" ? "nav-link-tab-active" : "nav-link"}`} onClick={() => { closeMenu()}}>
							Members
						</Link>
					</li>
					<li className="nav-item">
						<Link to="/alumini" className={`${location.pathname === "/alumini" ? "nav-link-tab-active" : "nav-link"}`} onClick={() => { closeMenu()}}>
						Alumni
						</Link>
					</li>
					<li className="nav-item">
						<Link to="/opportunities" className={`${location.pathname === "/opportunities" ? "nav-link-tab-active" : "nav-link"}`} onClick={() => { closeMenu()}}>
						Opportunities
						</Link>
					</li>
					<li className="nav-item">
						<Link to="/collaborators" className={`${location.pathname === "/collaborators" ? "nav-link-tab-active" : "nav-link"}`} onClick={() => { closeMenu()}}>
						Collaborators
						</Link>
					</li>
					<li className="nav-item">
						<Link to="/contact" className={`${location.pathname === "/contact" ? "nav-link-tab-active" : "nav-link"}`} onClick={() => { closeMenu()}}>
							Contact Us
						</Link>
					</li>
				</ul>
                {/* <div className='hide'></div> */}


			</nav>
		</div>
  	);}
export default NavBar;