import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import {db} from '../firebase';
import { collection, getDocs } from "firebase/firestore";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai"
// import { slider } from "../data/Data";

function Hero() {
    const [slide, setSlide] = useState(0);
    const [sliderData, setSliderData] = useState([]);
    const [loading , setLoading] = useState(false)

    const nextSlide = () => {
        setSlide(slide === sliderData.length - 1 ? 0 : slide + 1);
      };
    
      const prevSlide = () => {
        setSlide(slide === 0 ? sliderData.length - 1 : slide - 1);
      };

      const initialDataFetch = async() => {
        await getDocs(collection(db, "slider"))
            .then((querySnapshot)=>{               
                const newData = querySnapshot.docs
                    .map((doc) => ({...doc.data(), id:doc.id }));
                    setSliderData(newData);    
                    setLoading(false)            
        })
    };


    
      useEffect(() => {
        const intervalId = setInterval(() => {
          nextSlide();
        }, 3000);
    
        return () => clearInterval(intervalId);
      }, [sliderData]); 


    useEffect(() => {
        setLoading(true)
        initialDataFetch();

    }, []);


      return (
        <div className="carousel">
                  {sliderData && sliderData.length > 0 && 
<>
          <AiOutlineLeft onClick={prevSlide} className="arrow arrow-left" />
          {sliderData.map((item, index) => {
            return (
                <div key={index} className={slide === index ? "slide" : "slide slide-hidden"} style={{ transform: `translateX(${100 * (index - slide)}%)` }}>
                    <div className="image-container">
                    <img
                      src={item.imgURL}
                      alt={item.index}
                      className="slide-image"
                    />
                    </div>

              <div className="slide-content">
                <div></div>
                <div></div>
                <h1 className="slide-text">{item.title}</h1>
                  <Link to="/research" className="btn mt-4">
                      <span class="btn-inner">Learn More</span>
                  </Link>
                <div></div>
              </div>
              </div>
            );
          })}
          
          <AiOutlineRight onClick={nextSlide} className="arrow arrow-right"/>
          <span className="indicators">
            {sliderData.map((_, index) => {
              return (
                <button
                  key={index}
                  className={
                    slide === index ? "indicator" : "indicator indicator-inactive"
                  }
                  onClick={() => setSlide(index)}
                ></button>
              );
            })}
          </span>
          </>
}
        </div>

      );
    };

export default Hero;



