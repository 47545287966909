import React,{useState} from 'react'
import { collection, addDoc } from "firebase/firestore";

import {db} from '../firebase';
import { useForm } from "react-hook-form";

import "./contact.css";
import { BsGeoFill, BsEnvelopeAtFill, BsGlobeAmericas, BsFillEnvelopePaperFill, BsFillBuildingFill } from "react-icons/bs"
import ToastNotification from '../components/message/ToastNotification';
import { toast } from "react-toastify";

const Contact = () => {
    const {register,handleSubmit,formState: { errors }} = useForm();
    const [file, setFile] = useState(null);
    const [imgUrl, setImgUrl] = useState('');
    const [openForm, setOpenForm] = useState(true);


    // function handleChange(e) {
    //     if (e.target.files[0])
    //         setFile(e.target.files[0]);
    // }



      const onSubmit = async(data,e) => {

        const transformedData = {
              email: data.email,
              name:data.name,
              subject:data.subject,
              organisation : data.organisation,
              message : data.message
        };

       
            try {
            const docRef = await addDoc(collection(db, "contact"), transformedData);
            // e.target.reset();
            if(docRef.id){
                toast.success(" Message Successfully Sented", {
                    position: "top-center",
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                  });
              }
              else{
                toast.error(" Message Not Sent", {
                    position: "top-center",
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                  });
              }

          } catch (e) {
            console.error(" ");
          }
      
 
        
      };
    


    return (
        <section class="contact" id="contact">
            <ToastNotification />
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-12">
                        <div class="wrapper">
                            <div class="row no-gutters">
                                <div class="col-lg-7 col-md-7 col-sm-12 order-md-last d-flex align-items-stretch contact-area">
                                    <div class="contact-wrap w-100 p-md-5 p-4">
                                        {/* <h3 class="mb-4">Get in touch</h3> */}
                                        <div id="form-message-warning" class="mb-4"></div>
                                        <div id="form-message-success" class="mb-4">
                                            {/* Your message was sent, thank you! */}
                                        </div>
                                        <form onSubmit={handleSubmit(onSubmit)} id="contactForm" name="contactForm" class="contactForm">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label class="label" for="name">Full Name</label>
                                                        <input 
                                                            type="text" 
                                                            class="form-control" 
                                                            name="name" 
                                                            id="name" 
                                                            placeholder="Name" 
                                                            {...register("name", {required: true,})}   
                                                        />
                                                    </div>
                                                    {errors.name && errors.name.type === "required" && (
                                                        <p className="errorMsg" style={{fontSize:'12px',color:'red'}}>Name is required.</p>
                                                    )}
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label class="label" for="email">Email Address</label>
                                                        <input 
                                                            type="email" 
                                                            class="form-control" 
                                                            name="email" 
                                                            id="email" 
                                                            placeholder="Email" 
                                                            {...register("email", {required: true,})}/>
                                                    </div>
                                                    {errors.email && errors.email.type === "required" && (
                                                        <p className="errorMsg" style={{fontSize:'12px',color:'red'}}>Email is required.</p>
                                                    )}
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="label" for="subject">Organisation</label>
                                                        <input 
                                                            type="text" 
                                                            class="form-control" 
                                                            name="organisation" 
                                                            id="organisation" 
                                                            placeholder="Organisation" 
                                                            {...register("organisation", {required: false,})}
                                                        />
                                                    </div>
                                                    {errors.organisation && errors.organisation.type === "required" && (
                                                        <p className="errorMsg" style={{fontSize:'12px',color:'red'}}>Organisation is required.</p>
                                                    )}
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="label" for="subject">Subject</label>
                                                        <input 
                                                            type="text" 
                                                            class="form-control" 
                                                            name="subject" 
                                                            id="subject" 
                                                            placeholder="Subject" 
                                                            {...register("subject", {required: true,})}    
                                                        />
                                                    </div>
                                                    {errors.subject && errors.subject.type === "required" && (
                                                        <p className="errorMsg" style={{fontSize:'12px',color:'red'}}>Subject is required.</p>
                                                    )}
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="label" for="#">Message</label>
                                                        <textarea 
                                                            name="message" 
                                                            class="form-control" 
                                                            id="message" 
                                                            cols="30" rows="4" 
                                                            placeholder="Message"
                                                            {...register("message", {required: true,})}
                                                        ></textarea>
                                                    </div>
                                                    {errors.message && errors.message.type === "required" && (
                                                        <p className="errorMsg" style={{fontSize:'12px',color:'red'}}>Message is required.</p>
                                                    )}
                                                </div>



                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <input type="submit" value="Send Message" class="btn-primary" />
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                                <div class="col-lg-5 col-md-5 col-sm-12 d-flex align-items-stretch contact-area">
                                    <div class="info-wrap w-100 p-md-5 p-4">
                                        <h3>Contact Us</h3>
                                        <p class="mb-4">We're open for any suggestion or just to have a chat</p>

                                        <div class="dbox w-100 d-flex align-items-start">
                                            <div class="icon d-flex align-items-center justify-content-center">
                                                <BsGeoFill className="icon-img" />
                                            </div>
                                            <div class="text pl-3">
                                                <p><span>Address:</span> <span>Karthikeyan Vasudevan</span>
                                                    <span>Chief Scientist</span>
                                                    <span>
                                                        Laboratory for the Conservation of Endangered Species (LaCONES)
                                                        CSIR-Centre for Cellular and Molecular Biology
                                                        162 Pillar, PVNR Expressway, Attapur Ring Road,
                                                        Hyderguda, Hyderabad 500 048
                                                    </span>
                                                </p>
                                            </div>
                                        </div>

                                        <div class="dbox w-100 d-flex align-items-center">
                                            <div class="icon d-flex align-items-center justify-content-center">
                                                <BsFillBuildingFill className="icon-img" />
                                            </div>
                                            <div class="text pl-3">
                                                <p><span>Office:</span> <a href="tel://1234567920">+91-40-24006443</a></p>
                                            </div>
                                        </div>

                                        <div class="dbox w-100 d-flex align-items-center">
                                            <div class="icon d-flex align-items-center justify-content-center">
                                                <BsFillEnvelopePaperFill className="icon-img" />
                                            </div>
                                            <div class="text pl-3">
                                                <p><span>Fax:</span> <a href="tel://1234567920">+91-40-24006441</a></p>
                                            </div>
                                        </div>

                                        <div class="dbox w-100 d-flex align-items-center">
                                            <div class="icon d-flex align-items-center justify-content-center">
                                                <BsEnvelopeAtFill className="icon-img" />

                                            </div>
                                            <div class="text pl-3">
                                                <p><span>Email:</span> <a href=""><span class="__cf_email__" >karthik@ccmb.res.in </span></a></p>
                                            </div>
                                        </div>
                                        <div class="dbox w-100 d-flex align-items-center">
                                            <div class="icon d-flex align-items-center justify-content-center">
                                                <span class="fa fa-globe"></span>
                                                <BsGlobeAmericas className="icon-img" />
                                            </div>
                                            <div class="text pl-3">
                                                <p><span>Website</span> <a href="https://www.ccmb.res.in/ ">https://www.ccmb.res.in/Research/Research-Groups/Karthikeyan-Vasudevan</a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default Contact