import React from 'react';
import { MdAlternateEmail } from 'react-icons/md';
// import { v4 as uuidv4 } from 'uuid';

const AluminiCard = (props) => {
  const { id, name, position, email, year } = props;

  // Generate unique IDs for elements within the card
//   const cardId = `alumini-card-${uuidv4()}`;
//   const accordionId = `accordion-${uuidv4()}`;

  return (
    <div className="col-lg-3 col-md-6 col-sm-12 d-flex justify-content-center members-card">
      <div className="card">
        <div className="card-header">
          {/* <img className="card-avatar" src={img} alt="avatar" /> */}
          <h2 className="card-fullname">{name}</h2>
          <h2 className="card-jobtitle">{position}</h2>
          <h2 className="card-job-year">{year}</h2>
        </div>
        <div className="card-main">
          <div className="card-section is-active" id={id}>
            {/* <div className="card-content">
              <div className="card-subtitle">ABOUT</div>
              <input type="checkbox" id={accordionId} />
              <div className="accordion-height">
                <p className="card-desc">{about}</p>
              </div>
              <label className="read-more" htmlFor={accordionId}>
                Read More
              </label>
            </div> */}
            <div className="card-social">
              {email && email ? (
                <>
                  <a href={`mailto:${email}`}><MdAlternateEmail /></a>
                  <p className="social-link m-0">{email}</p>
                </>
              ) : (
                  <p className="social-link m-0">No email Available</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AluminiCard;
