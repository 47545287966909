import React,{useEffect,useState} from 'react'
import {db} from '../firebase';
import { collection, getDocs } from "firebase/firestore";
import { AiOutlineLink } from 'react-icons/ai';
import LoadingOverLay from '../components/loader/LoadingOverLay';
const PublicationBook = () => {




  const [publicationData, setPublicationData] = useState([]);
  const [sortPublicationData, setSortPublicationData] = useState([]);
  const [loading , setLoading] = useState(false)




  const handleButtonClick = (link) => {

      window.location.href = link;
  };


  function groupPublicationsByYear(publications) {
      // Create an object to store publications grouped by year
      const groupedPublications = {};
    
      // Iterate through each publication
      publications.filter((publication) => publication.type === 1).forEach((publication) => {
        const { publicationYear } = publication;
    
        // Check if the year key exists in the groupedPublications object
        if (!groupedPublications[publicationYear]) {
          // If not, create an array for that year
          groupedPublications[publicationYear] = [];
        }
    
        // Add the publication to the array corresponding to its year
        groupedPublications[publicationYear].push(publication);
      });
    
      return groupedPublications;
    }





  const initialDataFetch = async() => {
      await getDocs(collection(db, "publication"))
          .then((querySnapshot)=>{               
              const newData = querySnapshot.docs
                  .map((doc) => ({...doc.data(), id:doc.id }));
                  setPublicationData(newData);
                  const convertedPublication = newData.map(item => ({
                    ...item,
                    publicationYear: new Date(item.publicationYear).getFullYear(),
                }));  
                  let sortedPublications = groupPublicationsByYear(convertedPublication);
                  setSortPublicationData(sortedPublications);
                  setLoading(false)
           
          })
  };
  useEffect(() => {
    setLoading(true)
      initialDataFetch();


  }, []);



  return (



    <section className="publication" id="publication">
      <div className="container-fluid" style={{ padding: '0% 10%' }}>

      {sortPublicationData ? (

        <div className="row align-items-center justify-content-center">
                {loading && <LoadingOverLay />}

          {Object.keys(sortPublicationData).sort((a, b) => b - a).map((year) => (
            <div key={year} className="m-0 p-0">
              <div className='col-md-12 one'>
                <h1>{year}</h1>
              </div>
              {sortPublicationData[year].map((item, index) => (
                <div key={index} className="col-md-12 publication-container ">

                  <div className="content-wrapper">
                    <div className="index-wrapper">
                      <h5>{index + 1}</h5>
                    </div>
                    <div className="content">
                      <h5>{item.title}</h5>

                      <p>{item.description}</p>
                      <div className="download-wrapper">
                        {/* <div className="download-box pdf">
                          <AiFillFilePdf />
                          <a href={item.file} style={{ textDecoration: 'none' }}>
                            &ensp;{item.file ? `file1235.pdf` : 'No File Available'}
                          </a>
                        </div> */}
                        <div className="download-box site">
                          <AiOutlineLink style={{ color: 'blue' }} />
                          <button className='db-button' onClick={() => handleButtonClick(item.link)} style={{ padding: '10px', cursor: 'pointer' }}>
                            Reference link
                          </button>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
        ) : (
          <p style={{ textAlign: "center" }}>No Data Available{sortPublicationData.length}</p>
        )}
      </div>
    </section>

  )
}

export default PublicationBook