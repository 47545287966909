import React from 'react'
import NavBar from '../components/NavBar';
import Contact from '../screens/Contact';
import TitleCard from '../components/TitleCard';
import Footer from '../components/Footer';

const ContactUs = () => {
  return (
    <>
        <NavBar/>
        <TitleCard heading="Contact Us" subHeading="Reach Out to Animal Conservation Ecology Lab"/>
        <Contact />
        <Footer />

    </>
  )
}

export default ContactUs