import React from 'react'
import background from "../assets/images/slider1.jpg"
const TitleCard = (props) => {
    const {subHeading ,heading} = props
    return (
        <section class="title-card" id="title-card">

            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="content-container">
                        <img src={background} className='title-card-background' />

                        <div class="title-card-box">
                            <h4 class="card-title ">{heading}</h4>
                            <p class="mbr-text ">
                                {subHeading}
                            </p>

                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default TitleCard