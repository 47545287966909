import React, { useState } from 'react'
import NavBar from '../components/NavBar';
import TitleCard from '../components/TitleCard';
import Footer from '../components/Footer';
import PublicationPeer from '../screens/PublicationPeer';
import PublicationBook from '../screens/PublicationBook';


const PublicationPage = () => {

  const [displayPage, setDisplayPage] = useState(1);

  return (
    <>
      <NavBar />
      <TitleCard heading="Published Works" subHeading="Explore Our Contributions to Scientific Knowledge"/>
      <div className='col-md-12 publication-switch-container'>
        <div className={`switch-box ${displayPage === 1 ? 'active' : 'non-active'}`} style={{}} onClick={() => setDisplayPage(1)}>
          <a >Peer Reviewed Publication</a>
        </div>
        <div className={`switch-box ${displayPage === 2 ? 'active' : 'non-active'}`} onClick={() => setDisplayPage(2)}>
          <a>Books/Book Chapters</a>
        </div>
      </div>
      {displayPage === 1 && <PublicationPeer />}
      {displayPage === 2 && <PublicationBook />}
      
      <Footer />
    </>
  )
}

export default PublicationPage;