import React ,{useEffect,useState} from 'react'
import NavBar from '../components/NavBar';
import TitleCard from '../components/TitleCard';
import Footer from '../components/Footer';
import "./collaborators.css"
import {db} from '../firebase';
import { collection, getDocs } from "firebase/firestore";
export const Collaborators = () => {

  const [collaboratorData, setCollaboratorData] = useState([]);

  const initialDataFetch = async() => {
    await getDocs(collection(db, "collaborator"))
        .then((querySnapshot)=>{               
            const newData = querySnapshot.docs
                .map((doc) => ({...doc.data(), id:doc.id }));
                const sortedCollaborators = [...newData].sort((a, b) => a.collaboratorName.localeCompare(b.collaboratorName));
                setCollaboratorData(sortedCollaborators);   
                             
    })
};
useEffect(() => {
    initialDataFetch();

}, []);

  return (
    <>
      <NavBar />
      <TitleCard heading="Our Collaborators" subHeading="Building Alliances for Global Impact in Conservation"/>
      <div class="container collaborators">
        <div className='main-heading one'><h1>Collaborators</h1> </div>
        <div className='row'>
          {
            collaboratorData.map((item,index) => {
              return (
                <div class="col-md-12 col-sm-12" style={{display:'flex',justifyContent:'center'}}>
                <div class="card-collaborator">
                  <div class="personal-info">
                    <h3 className='heading'>{index + 1 }. &nbsp;{item.collaboratorName}</h3>
                    <p className='sub-heading'>{item.designation}  |  {item.organization}</p>
                    <p className='about-context'> {item.about}</p> 
                  </div>
                </div>
                </div>

              )
            })
          }
        </div>
      </div>
      <Footer />
    </>
  )
}


export default Collaborators