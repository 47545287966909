import React ,{useState,useEffect} from 'react'
import {db} from '../firebase';
import { collection, getDocs } from "firebase/firestore";
const GalleryHome = () => {

    const [showViewer, setShowViewer] = useState(false);
    const [currentImage, setCurrentImage] = useState(0);
    const [images, setImages] = useState([]);

  
    const openViewer = (index) => {

    };
  
    const initialDataFetch = async() => {
      await getDocs(collection(db, "gallery"))
          .then((querySnapshot)=>{               
              const newData = querySnapshot.docs
                  .map((doc) => ({...doc.data(), id:doc.id }));
                  var tempGroupedArray = [];
                  for (var i = 0; i < newData.length; i += 6) {
                    tempGroupedArray.push(newData.slice(i, i + 6));
                  }
                
                  setImages(newData);
                                
      })
    };
    
    
    
    useEffect(() => {

      initialDataFetch()
    }, []);

    
  
  return (
    <>
    {images.length>0 && 
    <div class="container d-flex justify-content-center">
    <div class="image-gallery" style={{gap:'0px',borderRadius:'0px',paddingBottom:'16px'}}>

    {images.map((data, index) => {
        if(index <= 5){
            return (
                <div className='image-grid' onClick={() => openViewer(index)}>
                    <img src={data.imgURL} alt="" style={{borderRadius:'0px'}}/>
                    {/* <div class="overlay"><span>{data.title}</span></div> */}
              </div>            
            );
        } 
      })}

    </div>


    {/* {showViewer && (
        <div className="image-viewer">
          <span className="close-button" onClick={closeViewer}>
            <AiOutlineClose />
          </span>
          <img src={gallery[currentImage].img} alt={`Image ${currentImage + 1}`} />
          <button onClick={prevImage} className="prev-button">
            <AiOutlineLeft />
          </button>
          <button onClick={nextImage} className="next-button">
            <AiOutlineRight />
          </button>
        </div>
      )} */}

  </div>

    }

</>
  )
}

export default GalleryHome