import React, { useState,useEffect } from 'react'
import "../App.css";
import {db} from '../firebase';
import { collection, getDocs } from "firebase/firestore";
import LoadingOverLay from '../components/loader/LoadingOverLay';

const Research = () => {

    const [activeButton, setActiveButton] = useState('a');
    const [researchData, setResearchData] = useState([]);
    const [loading , setLoading] = useState(false)

    const handleButtonClick = (button) => {
        setActiveButton(button);
    };

    const activebtn = {

        color: '#fff',
        backgroundColor: '#AD8E44',
        padding: '10px'
    }

    const nonactivebtn = {
        backgroundColor: '#fff',
        color: '#AD8E44',
        padding: '10px'
    }
    const initialDataFetch = async() => {
        await getDocs(collection(db, "research"))
            .then((querySnapshot)=>{               
                const newData = querySnapshot.docs
                    .map((doc) => ({...doc.data(), id:doc.id }));
                    setResearchData(newData);    
                    setLoading(false)            
        })
    };
    useEffect(() => {
        setLoading(true)
        initialDataFetch();

    }, []);


    return (

        <section class="research" id="research">
            <section className='research-section'>
                {/* <div className='col-md-12 research-button'>
                    <button className={activeButton === 'a' ? 'research-link-style-active' : 'research-link-style'}
                        onClick={() => handleButtonClick('a')}
                    >
                        <a href='#species-ecology' class="">Endangered Species Ecology</a>
                    </button>
                    <button className={activeButton === 'b' ? 'research-link-style-active' : 'research-link-style'}
                        onClick={() => handleButtonClick('b')}

                    >
                        <a href='#disease-ecology' class="">Disease Ecology</a>
                    </button>
                </div> */}
                
            </section>

            <div class="container-fluid p-5" >

                <div class="row align-items-center justify-content-center">

                    <div className='col-md-12 one' >
                        <h1 id='species-ecology'>Endangered Species Ecology</h1>
                       
                    </div>
                    {loading && <LoadingOverLay />}

                    {researchData.filter(item => item.type === 0).map((item, index) => {
                        return (

                            <div className={`col-12 col-sm-12 content-container ${index % 2 === 0 ? "no-reverse" : "reverse"}`}>
                                <div class="col-text col-md-6 col-sm-12 col-xs-12">
                                    <div class="card-wrapper">
                                        <div class="card-box">
                                            <h3>{item.title}</h3>
                                            <p>{item.description}</p>
                                            <h5>{item.team}</h5>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-img col-md-6 col-sm-12 col-xs-12">
                                    <div className={`image-wrapper ${index % 2 === 0 ? "text-align-end" : "text-align-start"}`}>

                                        <img src={item.imgURL} alt="Website" />
                                    </div>
                                </div>
                            </div>

                        );
                    })}


                </div>
            </div>


            <div class="container-fluid" id='disease-ecology'>
                <div class="row align-items-center justify-content-center">

                    <div className='col-md-12 one'>
                        <h1>Disease Ecology</h1>
                        
                    </div>

                    {researchData.filter(item => item.type === 1).map((item, index) => {
                        return (

                            <div className={`col-12 col-sm-12 content-container ${index % 2 === 0 ? "no-reverse" : "reverse"}`}>
                                <div class="col-text col-md-6 col-sm-12 col-xs-12">
                                    <div class="card-wrapper">
                                        <div class="card-box">
                                            <h3>{item.title}</h3>
                                            <p>{item.description}</p>
                                            <h5>{item.team}</h5>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-img col-md-6 col-sm-12 col-xs-12">
                                    <div className={`image-wrapper ${index % 2 === 0 ? "text-align-end" : "text-align-start"}`}>

                                        <img src={item.imgURL} alt="Website" />
                                    </div>
                                </div>
                            </div>

                        );
                    })}


                </div>
            </div>

        </section>


    )
}

export default Research