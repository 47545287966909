import React,{useState} from 'react';
import { MdAlternateEmail } from 'react-icons/md';

const TeamCard = (props) => {
  const { id, name, position, img, about, email } = props;

  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };


  return (
    <div className="col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center members-card">
      <div className="card">
        <div className="card-header">
          <img className="card-avatar" src={img} alt="avatar" />
          <h2 className="card-fullname">{name}</h2>
          <h2 className="card-jobtitle">{position}</h2>
          {/* <h2 className="card-job-year">{year}</h2> */}
        </div>
        <div className="card-main">
          <div className="card-section is-active">
            <div className="card-content">
              <div className="card-subtitle">ABOUT</div>

              <div className="accordion-height">
                <p className="card-desc">
                {expanded ? about : about.slice(0, 100) + '...'}
                    {about.length > 100 && (
                      <span onClick={toggleExpanded}>
                        {expanded ? 'Read less' : 'Read more'}
                      </span>
                )}
                
                </p>




              </div>
              
              {/* <label className="read-more" htmlFor={accordionId}>
                {about != '' ? 'Read More' : ''}
                
              </label> */}


            </div>


            <div className="card-social">
              <a href="#">
                <MdAlternateEmail />
              </a>
              <p className="social-link m-0"> {email}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamCard;
