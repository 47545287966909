import React,{useEffect,useState} from 'react'
import {db} from '../firebase';
import { collection, getDocs } from "firebase/firestore";
function Agent() {

    const [memberData, setMemberData] = useState([]);
    const [loading , setLoading] = useState(false)

    const initialDataFetch = async() => {
        await getDocs(collection(db, "members"))
            .then((querySnapshot)=>{               
                const newData = querySnapshot.docs
                    .map((doc) => ({...doc.data(), id:doc.id }));
 
                    const sortedTeam = [...newData].filter(item => item.type === 0 && item.designationId === 0);

                    setMemberData(sortedTeam[0]);  
                    setLoading(false)
            })
    };
    useEffect(() => {
      setLoading(true)
        initialDataFetch();
    
    
    }, []);


    return (
        <>
        {memberData && 
            <div className="container card-detail">
                <div className='row card-details m-0'>

                    <div className="col-lg-3 col-sm-12" >
                        <img src={memberData.imgURL} alt="Image" style={{width:'280px',height:'280px'}}/>
                    </div>

                    <div className="col-lg-8 col-sm-12 agent-details">
                        <div className='w-100 detail-head'>
                            <h2>{memberData.name}</h2>
                            <span>{memberData.designationName}</span>
                        </div>
                        <div className='line'></div>
                        <div className='w-100 detail-para'>
                            <p>Animal ecology and conservation biology are my research interests. I am fascinated by amphibians and reptiles and my research revolves around them.</p>
                        </div>
                        {/* <div className='w-100'>
                            <Link to="/" className="btn">
                                <span class="btn-inner">Download CV</span>
                            </Link>
                        </div> */}
                    </div>

                </div>
            </div>
        }
        </>
    )
}

export default Agent