import React,{useEffect,useState} from 'react'
import NavBar from '../components/NavBar';
import TitleCard from '../components/TitleCard';
import Footer from '../components/Footer';
import AluminiCard from './AluminiCard';
import {db} from '../firebase';
import { collection, getDocs } from "firebase/firestore";

export const Alumini = () => {

  const [memberData, setMemberData] = useState([]);

  const initialDataFetch = async() => {
    await getDocs(collection(db, "members"))
        .then((querySnapshot)=>{               
            const newData = querySnapshot.docs
                .map((doc) => ({...doc.data(), id:doc.id }));
                const sortedAlumini = [...newData].filter(item => item.type === 1).sort((a, b) => a.name.localeCompare(b.name));
                const convertedAlumini = sortedAlumini.map(item => ({
                  ...item,
                  resignationYear: new Date(item.resignationYear).getFullYear(),
                }));
                setMemberData(convertedAlumini);   
        })
};
useEffect(() => {
    initialDataFetch();


}, []);

  return (
    <>
      <NavBar />
      <TitleCard heading="Alumni Network" subHeading="Past Members" />
      <section id="team" class="team alumini content-section">
      <div class="container">
        <div class="row justify-content-center">
            <div className='col-md-12,col-sm-12 team-heading-container'>
                <h3 className='team-heading'>Alumni</h3>
            </div>

          {memberData.map((data, index) => {
                return (
                  <AluminiCard name={data.name} position={data.designationName} email={data.email} year={data.resignationYear}  index={index}/>
                );
          })}
        </div>
      </div>
</section>
      <Footer />
    </>
  )
}


export default Alumini